import Vue from "vue";
import titleMixin from "@/mixins/title";
import { getToday } from "@/helpers/dateTimeHelper";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("customersTimeSlots.create.title"),
            data: {
                name: "",
                startTime: getToday(false),
                endTime: getToday(false),
                order: 0
            },
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    startTime: {
                        required: true
                    },
                    endTime: {
                        required: true,
                        dateAfter: [
                            (args) => {
                                const startTime = args.element.form.querySelector(`#startTime`)
                                    .ej2_instances[0].value;
                                const endTime = args.element.form.querySelector(`#endTime`)
                                    .ej2_instances[0].value;
                                return startTime < endTime;
                            },
                            this.$t("dailyWorkAvailability.endTimeMustBeGreaterThanStartTime")
                        ]
                    },
                    order: {
                        required: true,
                        min: 0
                    }
                }
            }
        };
    },
    methods: {
        create() {
            return this.$store.dispatch("customerTimeSlot/create", {
                timeSlot: this.data
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("customersTimeSlots.create.toastSuccessTitle"), this.$t("customersTimeSlots.create.toastSuccessContent", {
                name: this.data.name
            }));
            return this.$router.push("/customers-time-slots");
        },
        cancel() {
            return this.$router.push("/customers-time-slots");
        }
    }
});
